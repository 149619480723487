/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

import MoCoCNMsisdnErrors from '../../../lib/mococn/msisdn-errors';

export default {
  match($el) {
    return (
      $el.attr('data-error-type') &&
      $el.attr('pattern') &&
      $el.hasClass('mococn-msisdn')
    );
  },

  validate() {
    if (!new RegExp(this.attr('pattern')).test(this.val())) {
      throw MoCoCNMsisdnErrors.toError(this.attr('data-error-type'));
    }
  },
};
