/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
define([], function() {
  'use strict';

  return {
    INVALID_TIMESTAMP: 1452,
    INVALID_TOKEN: 110,
  };
});
