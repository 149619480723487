/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

import $ from 'jquery';
import MoCoCNMsisdnErrors from '../../../lib/mococn/msisdn-errors';

function onInputButtonClick(handler) {
  return function(event) {
    var button = $(event.target),
      input = button.prev(),
      parentRow = button.parent();

    button.attr('disabled', 'disabled').html(button.attr('data-pending'));
    input.attr('disabled', 'disabled');

    Promise.resolve()
      .then(() => {
        try {
          input.validate();
        } catch (e) {
          console.error(e);
          throw MoCoCNMsisdnErrors.toError(input.attr('data-error-type'));
        }
        // hack to hide the validation error tooltip
        input.trigger('change');

        return this.invokeHandler(handler, this.getElementValue(input)).then(
          retryDelay => {
            var rowActionOnSuccess = parentRow.attr('data-success');
            switch (rowActionOnSuccess) {
              case 'show-next':
                parentRow.next().removeClass('hidden');
                break;
              case 'disable-prev':
                var prevRow = parentRow.prev();
                var prevButton = prevRow.find('button');
                this.clearInterval(this._retryIntervals[prevButton.attr('id')]);
                prevButton
                  .attr('disabled', 'disabled')
                  .html(prevButton.attr('data-success'));
                prevRow.find('input').attr('disabled', 'disabled');
                break;
              default:
                break;
            }

            if (retryDelay) {
              this._nextTries = this._nextTries || {};
              this._retryIntervals = this._retryIntervals || {};
              var buttonId = button.attr('id');

              this._nextTries[buttonId] = Date.now() + retryDelay * 1e3;
              this.clearInterval(this._retryIntervals[buttonId]);
              this._retryIntervals[buttonId] = this.setInterval(() => {
                var remaining = Math.ceil(
                  (this._nextTries[buttonId] - Date.now()) / 1e3
                );
                if (remaining > 0) {
                  button.html(remaining + button.attr('data-second-suffix'));
                } else {
                  this.clearInterval(this._retryIntervals[buttonId]);

                  input.removeAttr('disabled');
                  button.removeAttr('disabled').html(button.attr('data-retry'));
                }
              }, 111);
            } else {
              button.html(button.attr('data-success'));
            }
          }
        );
      })
      .then(null, err => {
        console.error(err);

        if (
          MoCoCNMsisdnErrors.created(err) &&
          MoCoCNMsisdnErrors.is(err, 'UNEXPECTED_ERROR')
        ) {
          if (err.error === 'Timeout error') {
            err = MoCoCNMsisdnErrors.toError('SERVER_BUSY');
          } else {
            err = MoCoCNMsisdnErrors.toError(input.attr('data-error-type'));
          }
        }
        this.showValidationError(input, err);
        input.removeAttr('disabled');
        button.removeAttr('disabled').html(button.attr('data-normal'));
      });
  };
}

export default onInputButtonClick;
