/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

import _ from 'underscore';

var Mixin = {
  setInterval(callback, intervalMS) {
    if (!this._intervals) {
      this._intervals = [];
      this.on('destroy', clearAllIntervals.bind(this));
    }

    var win = this.window || window;

    var interval = win.setInterval(function() {
      callback.call(this);
    }, intervalMS);

    this._intervals.push(interval);

    return interval;
  },

  clearInterval(interval) {
    var win = this.window || window;
    win.clearInterval(interval);

    this._intervals = _.without(this._intervals, interval);
  },
};

function clearAllIntervals() {
  var win = this.window || window;

  _.each(this._intervals, function(interval) {
    win.clearInterval(interval);
  });

  this._intervals = null;
}

export default Mixin;
