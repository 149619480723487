import Cocktail from 'cocktail';
import FormView from '../form';
import onInputButtonClick from '../decorators/mococn/input_button';
import KeyCodes from '../../lib/key-codes';
import MoCoCNIntervalMixin from '../mixins/mococn-interval-mixin';
import MoCoCNMsisdnErrors from '../../lib/mococn/msisdn-errors';
import Template from 'templates/mococn/msisdn-verify.mustache';

var View = FormView.extend({
  template: Template,
  className: 'mococn-msisdn-verify',

  events: {
    'click #mococn-msisdn-sendsms': 'onMsisdnSendSMSClick',
    'click #mococn-msisdn-verify': 'onMsisdnVerifyClick',
    'input input': 'onInput',
    'keypress input': 'onKeyPress',
  },

  initialize(options = {}) {
    this._account = this.user.initAccount(this.model.get('account'));

    this.onSubmitComplete = this.model.get('onSubmitComplete');
  },

  getAccount() {
    return this._account;
  },

  beforeRender() {
    // any better navigation choices ?
    var account = this.getAccount();

    if (!account.get('sessionToken')) {
      this.navigate('signup');
    }

    if (!account.get('verified')) {
      this.navigate('confirm', { account });
    }
  },

  submit() {
    var account = this.getAccount();
    var mococnMsisdnAssertion = this._mococnMsisdnAssertion;

    return account.mococnProfileUpdate({ mococnMsisdnAssertion }).then(() => {
      delete this._mococnMsisdnAssertion;
      // should update the persisted mococnMsisdn ?
      return this.onSubmitComplete(account);
    });
  },

  isValidEnd() {
    return this._mococnMsisdnAssertion !== undefined;
  },

  showValidationErrorsStart() {
    if (!this._msisdnSessionToken) {
      this.$('#mococn-msisdn-sendsms')
        .get(0)
        .click();
    } else if (!this._verifiedMSISDN) {
      this.$('#mococn-msisdn-verify')
        .get(0)
        .click();
    }
    return true;
  },

  onInput(event) {
    var element = this.$(event.target);
    var button = element.next();
    try {
      element.validate();
      button.removeClass('disabled');
    } catch (e) {
      button.addClass('disabled');
    }
  },

  /*
   * This has to be keypress, or autocomplete doesn't work as expected.
   * See https://bugzil.la/1121040 for more.
   */
  onKeyPress(event) {
    if (event.which === KeyCodes.ENTER) {
      event.preventDefault();

      var next = this.$(event.target).next();
      next.get(0).click();
    }
  },

  onMsisdnSendSMSClick: onInputButtonClick(function(value) {
    var mococnMsisdnClient = this.getAccount()._mococnMsisdnClient;

    return mococnMsisdnClient
      .register()
      .then(result => {
        var msisdn = '+86' + value;
        if (msisdn === this._verifiedMSISDN) {
          throw MoCoCNMsisdnErrors.toError('THROTTLED_MSISDN');
        } else {
          delete this._verifiedMSISDN;
          if (this._mococnMsisdnAssertion) {
            delete this._mococnMsisdnAssertion;
          }
        }

        this._msisdnSessionToken = result.msisdnSessionToken;

        return mococnMsisdnClient.smsVerify(
          msisdn,
          '460',
          this._msisdnSessionToken
        );
      })
      .then(() => {
        return 60;
      })
      .then(null, err => {
        delete this._msisdnSessionToken;
        if (
          MoCoCNMsisdnErrors.created(err) &&
          MoCoCNMsisdnErrors.is(err, 'SERVER_BUSY')
        ) {
          switch (err.error) {
            case 'isv.BUSINESS_LIMIT_CONTROL':
              err = MoCoCNMsisdnErrors.toError('THROTTLED_MSISDN');
              break;
            case 'isv.MOBILE_NUMBER_ILLEGAL':
              err = MoCoCNMsisdnErrors.toError('INVALID_MSISDN');
              break;
            default:
              break;
          }
        }
        throw err;
      });
  }),

  onMsisdnVerifyClick: onInputButtonClick(function(value) {
    if (!this._msisdnSessionToken) {
      throw MoCoCNMsisdnErrors.toError('INVALID_TOKEN');
    }

    var mococnMsisdnClient = this.getAccount()._mococnMsisdnClient;

    return mococnMsisdnClient
      .smsVerifyCode(value, this._msisdnSessionToken)
      .then(result => {
        this._verifiedMSISDN = result.msisdn;
        var assertion = mococnMsisdnClient._assertionLibrary;
        return assertion.generate(this._msisdnSessionToken);
      })
      .then(assertion => {
        this._mococnMsisdnAssertion = assertion;
      })
      .then(() => {
        return mococnMsisdnClient
          .unregister(this._verifiedMSISDN, this._msisdnSessionToken)
          .then(
            () => {
              delete this._msisdnSessionToken;
            },
            err => {
              console.error(err);
            }
          );
      })
      .then(() => {
        return 0;
      });
  }),
});

Cocktail.mixin(View, MoCoCNIntervalMixin);

export default View;
