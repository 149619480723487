/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

import _ from 'underscore';
import Errors from '../../lib/errors';

var t = function(msg) {
  return msg;
};

var ERRORS = {
  UNVERIFIED_ACCOUNT: {
    errno: 104,
    message: t('Unverified account'),
  },
  INVALID_VERIFICATION_CODE: {
    errno: 105,
    message: t('Invalid verification code'),
  },
  INVALID_JSON: {
    errno: 106,
    message: t('Invalid JSON in request body'),
  },
  INVALD_PARAMETER: {
    errno: 107,
    message: t('Invalid parameter in request body: %(param)s'),
  },
  MISSING_PARAMETER: {
    errno: 108,
    message: t('Missing parameter in request body: %(param)s'),
  },
  INVALID_REQUEST_SIGNATURE: {
    errno: 109,
    message: t('Invalid request signature'),
  },
  INVALID_TOKEN: {
    errno: 110,
    message: t('Invalid token'),
  },
  EXPIRED_CODE: {
    errno: 111,
    message: t('Expired code'),
  },
  MISSING_CONTENT_LENGTH_HEADER: {
    errno: 112,
    message: t('Missing content-length header'),
  },
  REQUEST_TOO_LARGE: {
    errno: 113,
    message: t('Request body too large'),
  },
  THROTTLED_VERIFY: {
    errno: 114,
    message: t('Attempt limit exceeded'),
  },
  THROTTLED_MSISDN: {
    errno: 115,
    message: t('Please do not send SMS in quick succession'),
  },
  THROTTLED_VERIFYCODE: {
    errno: 116,
    message: t('Attempt limit exceeded'),
  },
  THROTTLED_UNSPECIFIED: {
    errno: 117,
    message: t('Attempt limit exceeded'),
  },
  SERVER_BUSY: {
    errno: 201,
    message: t('Server busy, try again soon'),
  },
  UNEXPECTED_ERROR: {
    errno: 999,
    message: t('Unexpected error'),
  },

  INVALID_MSISDN: {
    errno: 1451,
    message: t('Invalid phone number'),
  },
  INVALID_TIMESTAMP: {
    errno: 1452,
    message: t('Invalid timestamp in request signature'),
  },
};

export default _.extend({}, Errors, {
  ERRORS: ERRORS,
  NAMESPACE: 'msisdn',
});
