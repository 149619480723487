/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

import AuthErrors from '../../lib/auth-errors';

export default {
  match($el) {
    return $el.attr('type') === 'checkbox';
  },

  val(val) {
    if (this.attr('id') === 'explicitConsent') {
      return (!!this.is(':checked')).toString();
    }

    return !!this.is(':checked');
  },

  validate() {
    if (this.attr('id') !== 'explicitConsent') {
      return;
    }

    if (this.prop('validationMessage')) {
      throw AuthErrors.toError('MOCOCN_EXPLICIT_CONSENT_REQUIRED');
    }
  },
};
