/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

import Constants from '../../lib/constants';

export default {
  getMoCoCNPasswordPattern() {
    const minLength =
      this.relier.has('mococnLegacyPassword') &&
      this.relier.get('mococnLegacyPassword')
        ? Constants.PASSWORD_LEGACY_MIN_LENGTH
        : Constants.PASSWORD_MIN_LENGTH;
    return `.{${minLength},}`;
  },

  setInitialContext(context) {
    const mococnPasswordPattern = this.getMoCoCNPasswordPattern();

    context.set({
      mococnPasswordPattern,
    });
  },
};
