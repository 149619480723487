import Constants from '../../lib/constants';

import MsisdnErrors from './msisdn-errors';

import hawkCredentials from './hawkCredentials';
import Request from './request';

var HKDF_SIZE = 2 * 32;

function MsisdnClient(options) {
  options = options || {};

  this.request = new Request(options.msisdnUrl, options.xhr, {
    localtimeOffsetMsec: options.localtimeOffsetMsec,
    timeout: options.timeout || Constants.DEFAULT_XHR_TIMEOUT_MS,
  });
}

MsisdnClient.prototype = {
  _request(path, method, credentials, jsonPayload) {
    return this.request
      .send(path, method, credentials, jsonPayload)
      .then(null, result => {
        if (result.errno) {
          throw MsisdnErrors.toError(result);
        }
      });
  },

  discover(msisdn, mcc, mnc, roaming) {
    return this._request('/discover', 'POST', null, {
      mcc: mcc,
      mnc: mnc,
      msisdn: msisdn,
      roaming: roaming,
    });
  },

  register() {
    return this._request('/register', 'POST');
  },

  unregister(msisdn, sessionToken) {
    return hawkCredentials(sessionToken, 'sessionToken', HKDF_SIZE, true).then(
      credentials => {
        return this._request('/unregister', 'POST', credentials, {
          msisdn: msisdn,
        });
      }
    );
  },

  smsVerify(msisdn, mcc, sessionToken) {
    return hawkCredentials(sessionToken, 'sessionToken', HKDF_SIZE, true).then(
      credentials => {
        return this._request('/sms/mt/verify', 'POST', credentials, {
          mcc: mcc,
          msisdn: msisdn,
          shortVerificationCode: true,
        });
      }
    );
  },

  smsVerifyCode(verificationCode, sessionToken) {
    return hawkCredentials(sessionToken, 'sessionToken', HKDF_SIZE, true).then(
      credentials => {
        return this._request('/sms/verify_code', 'POST', credentials, {
          code: verificationCode,
        });
      }
    );
  },

  certificateSign(publicKey, durationInMS, sessionToken) {
    return hawkCredentials(sessionToken, 'sessionToken', HKDF_SIZE, true).then(
      credentials => {
        return this._request('/certificate/sign', 'POST', credentials, {
          duration: durationInMS / 1e3,
          publicKey: JSON.stringify(publicKey),
        });
      }
    );
  },
};

export default MsisdnClient;
